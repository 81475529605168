import React, { useEffect, useState } from 'react'
import { Layout, Button, List, AutoComplete } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import logo from './smashine_turquoise_key.png'; // Tell Webpack this JS file uses this image
import Gallery from 'react-photo-gallery';
import photo1 from './smashine_turquoise_key.png'; // Tell Webpack this JS file uses this image
import photo2 from './smashine_turquoise_key.png'; // Tell Webpack this JS file uses this image

const config = {
  apiUrl: 'https://type.fit/api/quotes',
  repoUrl: 'https://github.com/ssokurenko/quotes-react-app'
}

const { Header, Content } = Layout

const photos = [
  {
    src: '/photos/20191126_234211.jpg',
    width: 1,
    height: 1
  },
  {
    src: '/photos/20191211_221421.jpg',
    width: 1,
    height: 1
  },
  {
    src: '/photos/20200215_192402.jpg',
    width: 3,
    height: 2
  },
  {
    src: '/photos/20200702_001530.jpg',
    width: 1,
    height: 1
  },
  {
    src: '/photos/20210320_191953.jpg',
    width: 2,
    height: 1
  },
  {
    src: '/photos/20210325_002317.jpg',
    width: 1,
    height: 1
  },
  {
    src: '/photos/20220116_185643.jpg',
    width: 2,
    height: 1
  },
  {
    src: '/photos/20220705_232413.jpg',
    width: 1,
    height: 1
  },
  {
    src: '/photos/20220714_235015.jpg',
    width: 1,
    height: 1
  },
  {
    src: '/photos/20230319_1938412.jpg',
    width: 2,
    height: 1
  },
  {
    src: '/photos/20221218_134954.jpg',
    width: 1,
    height: 1
  },
  {
    src: '/photos/received_641616733481539.jpeg',
    width: 2,
    height: 1
  },
  {
    src: '/photos/Sunday0086.jpg',
    width: 2,
    height: 2
  },
  {
    src: '/photos/20221004_1422402.jpg',
    width: 2,
    height: 2
  }
];

function Home() {
  const [quotes, setQuotes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const Quote = ({ text, author }) => {
    return (
      <div style={{width:'100%',textAlign:'center'}}>
        <div style={{margin:'auto', padding:50}}>
          <Gallery photos={photos} />
          <br />
          {/* <strong>{text}</strong> */}
        </div>
      </div>
    )
  }

  const getQuotes = async () => {
    setQuotes([])
    setIsLoading(true)
    fetch(config.apiUrl)
      .then(function (response) {
        return response.json()
      })
      .then((data) => {
        setQuotes(data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    const url = "https://randomuser.me/api/?results=5";

    const getQuotes = async () => {
      setQuotes([])
      setIsLoading(true)
      fetch(config.apiUrl)
        .then(function (response) {
          return response.json()
        })
        .then((data) => {
          let min = 0;
          let max = data.length;
          let random = Math.floor(Math.random() * (+max - +min)) + +min;
          console.log(random)
          setQuotes([data[random]])
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }

    getQuotes();
  }, []);

  return (
    <Layout>
      <Header>
        <div className="container" style={{minWidth:500}}>
          <span className="site-logo" >The SamZone <img src={logo} alt="Logo" height={50} /></span>
        </div>
      </Header>
      <Content className="container">
        <List
          size="small"
          loading={isLoading}
          header={<span style={{'display':'block','textAlign':'center','fontWeight':'bold'}}>Hey there gorgeous lady</span>}
          footer={
            <div>
              <span style={{'display':'block','textAlign':'center','fontWeight':'bold'}}>Have *a* wonderfully fantastic day :)</span>
            </div>
          }
          bordered
          dataSource={quotes}
          renderItem={(quote) => (
            <List.Item>
              <Quote text={quote.text} author={quote.author} />
            </List.Item>
          )}
        />
      </Content>
    </Layout>
  )
}

export default Home
